<template>
  <div class="box">
    <img src="@/img/report/yangli.jpg" class="bg" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    toInfo(index) {
      this.$router.push({
        path: "caseInfo",
        query: { index: index },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background-size: 100%;
  min-height: calc(100vh - 80px);
  // display: flex;
  // justify-content: center;
  // flex-wrap: wrap;
  padding-top: 80px;
  text-align: center;
  .bg {
    width: 100%;
  }
}
</style>
